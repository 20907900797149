import OverrideModal from './OverrideModal';
import AnalyticsProvider from './lib/segment/AnalyticsProvider';
import type { AuthModalOverrides } from 'src/types';

interface Props {
  /**
   * JSON serialized, URI encoded value of AuthModalOverrides
   */
  props: string;
}

const OverrideModalWrapper: React.FC<Props> = ({ props }) => {
  let authModalOverride: AuthModalOverrides;
  try {
    authModalOverride = JSON.parse(decodeURIComponent(props));
  } catch (e) {
    return null;
  }
  return (
    <AnalyticsProvider>
      <OverrideModal authModalOverride={authModalOverride} />
    </AnalyticsProvider>
  );
};

export default OverrideModalWrapper;
